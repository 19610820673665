import moment from "moment";

export default class ReceiptModel {
    url: string;
    billDate: Date;
    amount: number;
    currency: string;

    constructor(obj: any) {
        this.url = obj.url;
        this.billDate = moment(obj.billDate).toDate();
        this.amount = Number(obj.amount);
        this.currency = obj.currency;
    }
}
