
import Vue from "vue";
import { EventBus } from "../eventBus";

export default Vue.extend({
    name: "NotificationComponent",
    props: ["darkMode"],
    data: () => ({
        // snackbar
        snackbar: false,
        snackbarMessage: "",
        color: "primary"
    }),
    mounted() {
        EventBus.$on("notify-info", (msg: any) => {
            this.color = "primary";
            this.snackbarMessage = msg;
            this.snackbar = true;
        });

        EventBus.$on("notify-error", (msg: any) => {
            this.color = "red";
            this.snackbarMessage = msg;
            this.snackbar = true;
        });
    }
});
