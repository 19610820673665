import moment from "moment";
import PlanType from "./planType";
import PlanOrigin from "./planOrigin";
import { UserRoleType } from "./user.role.type.model";

class User {
    email = "";
    emailConfirmed = false;
    plan: Plan | null = null;
    isAdmin = true;
    roleType: UserRoleType = UserRoleType.Customer;
    invoicesAvailable = false;

    constructor(data: any) {
        if (!data) {
            return;
        }
        this.email = data.email;
        this.emailConfirmed = !!data.emailConfirmed;
        this.roleType = getUserRoleType(data.roleType);

        this.plan = data.plan ? new Plan(data.plan) : null;

        this.invoicesAvailable = !!data.invoicesAvailable;
    }
}

function getUserRoleType(input: any) {
    switch (input) {
        case UserRoleType.Admin:
            return UserRoleType.Admin;
        case UserRoleType.SuperAdmin:
            return UserRoleType.SuperAdmin;
        default:
            return UserRoleType.Customer;
    }
}

class Plan {
    id: string;
    name: string;
    recurring: boolean;
    cancelledInPaymentSystem: boolean;
    isChildOrder: boolean;
    isTeamAdmin: boolean;
    validUntil: Date;
    planType: PlanType;
    planOrigin: PlanOrigin;
    licenseCount: number;

    constructor(data: any) {
        this.validUntil = moment(data.validUntil).toDate();
        this.name = data.name;
        this.id = data.id;
        this.isChildOrder = Boolean(data.isChildOrder);
        this.isTeamAdmin = Boolean(data.isTeamAdmin);
        this.planType = getPlanType(data.type);
        this.planOrigin = getPlanOrigin(data.origin);
        this.recurring = Boolean(data.isRecurring);
        this.cancelledInPaymentSystem = Boolean(data.isCancelledInPs);
        this.licenseCount = Number(data.licenseCount) || (this.planType == PlanType.None || this.planType == PlanType.TrialWeekly ? 0 : 1);
    }

    get canBeRenewed(): boolean {
        const diff = moment(this.validUntil).diff(moment.utc(), "years");
        return isAnnualPlan(this.planType) && (!this.isChildOrder || this.isTeamAdmin) && diff < 1;
    }

    get canBeStopped(): boolean {
        return !this.isChildOrder || this.isTeamAdmin;
    }

    get expired(): boolean {
        return moment(this.validUntil) < moment.utc();
    }

    get licenseCanScale(): boolean {
        return (!this.isChildOrder || this.isTeamAdmin) && this.planOrigin == PlanOrigin.Paddle && !this.cancelledInPaymentSystem;
    }
}

function isAnnualPlan(planType: PlanType): boolean {
    return (
        planType == PlanType.Annually ||
        planType == PlanType.IndividualAnnually_1 ||
        planType == PlanType.IndividualAnnually_2_5 ||
        planType == PlanType.IndividualAnnually_6_10 ||
        planType == PlanType.IndividualAnnually_11_25
    );
}

function getPlanType(x: number): PlanType {
    if (Object.keys(PlanType).includes(x.toString())) {
        return x;
    }
    return PlanType.None;
}

function getPlanOrigin(x: number): PlanOrigin {
    if (Object.keys(PlanOrigin).includes(x.toString())) {
        return x;
    }
    return PlanOrigin.None;
}

export { User };
