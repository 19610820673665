
class Link {
    title;
    routeName;
    constructor(t: string, r: string) {
        this.title = t;
        this.routeName = r;
    }
}

import Vue from "vue";
import userService from "./services/user.service";
import InitialUserFetchResult from "./models/initialUserFetchResult";
import Notification from "./components/Notification.vue";
import CookieBanner from "./components/CookieBanner.vue";

export default Vue.extend({
    name: "App",
    components: { Notification, CookieBanner },
    props: {
        darkMode: Boolean
    },
    data: () => ({
        drawer: false,
        isDarkMode: false,
        pageMeta: {
            title: "",
            image: "https://blisk.io/images/CDN/branding/Blisk-logos-and-texts/Blisk-logo-text-headline-256-dark.jpg",
            description: "Blisk is a browser for web developers. Toolbox for development, debugging and testing",
            keywords: "Blisk, browser, blisk.io"
        }
    }),
    metaInfo() {
        return {
            title: this.pageMeta.title,
            titleTemplate: "%s | Blisk App",

            meta: [
                { name: "robots", content: "noindex" },
                { name: "googlebot", content: "noindex" },
                { name: "author", content: "Blisk Team" },
                { vmid: "description", name: "description", content: this.pageMeta.description },
                { vmid: "keywords", name: "keywords", content: this.pageMeta.keywords },
                { property: "og:type", content: "website" },
                { vmid: "og:image", property: "og:image", content: this.pageMeta.image },
                { vmid: "twitter:image", name: "twitter:image", content: this.pageMeta.image },
                { vmid: "msapplication-TileImage", name: "msapplication-TileImage", content: this.pageMeta.image },
                { property: "og:url", content: location.href },
                { property: "og:site_name", content: "Blisk" },
                { vmid: "og:title", property: "og:title", content: this.pageMeta.title },
                { vmid: "og:description", property: "og:description", content: this.pageMeta.description },
                { name: "twitter:card", content: "summary" },
                { name: "twitter:site", content: "@BliskBrowser" },
                { vmid: "twitter:title", name: "twitter:title", content: this.pageMeta.title },
                { vmid: "twitter:description", name: "twitter:description", content: this.pageMeta.description }
            ]
        };
    },

    methods: {
        async logOut() {
            await userService.logout();
            this.$store.commit("setUser", null);
            this.$router.push({ name: "home" });
        }
    },
    computed: {
        isFullWidthLayout() {
            return this.$route.meta && this.$route.meta.layout == "full-width" ? true : false;
        },
        isGrayBgLayout() {
            return this.$route.meta && this.$route.meta.pageBgColor == "light-gray" ? true : false;
        },
        isFillHeight() {
            return this.$route.meta && this.$route.meta.fillHeight ? true : false;
        },
        userFetchedOnce() {
            return this.$store.state.userFetchedOnce;
        },
        isBliskBrowserNavigator() {
            return this.$store.state.isBliskBrowserNavigator;
        },
        isAuthenticated() {
            return !!this.$store.state.user;
        },
        isChildOrder() {
            return !!this.$store.state.user && this.$store.state.user.plan.isChildOrder && !this.$store.state.user.plan.isTeamAdmin;
        },
        headerLinks(): Array<any> {
            if (this.isAuthenticated) {
                return [
                    { title: "Screenshots", url: "screenshot-list", visible: true },
                    { title: "Screen Records", url: "screenrecord-list", visible: true },
                    { title: "Cloud Storage", url: "storage", visible: true },
                    { title: "Pricing", url: "pricing", visible: !this.isChildOrder },
                    { title: "Contact Us", url: "contact", visible: true },
                    { title: "Account", url: "account", visible: true },
                    { title: "Log Out", url: "logoff", visible: true }
                ];
            }
            return [
                { title: "Pricing", url: "pricing", visible: !this.isBliskBrowserNavigator },
                { title: "Contact Us", url: "contact", visible: true },
                { title: "Create an account", url: "register", visible: true },
                { title: "Log In", url: "login", visible: true }
            ];
        },
        isFetchingUserInfo() {
            return this.$store.state.initialUserFetchResult == InitialUserFetchResult.InProgress;
        },
        isFetchingUserInfoFailed() {
            return this.$store.state.initialUserFetchResult == InitialUserFetchResult.Failed;
        }
    },
    beforeMount() {
        if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
            this.isDarkMode = true;
        }
        this.$vuetify.theme.dark = this.isDarkMode;

        if (this.isBliskBrowserNavigator) {
            document.documentElement.classList.add("isBliskBrowserNavigator");
        }
    },
    mounted() {
        const darkModePreference = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)");
        darkModePreference.onchange = (e) => {
            if (e.matches) {
                this.isDarkMode = true;
            } else {
                this.isDarkMode = false;
            }
            this.$vuetify.theme.dark = this.isDarkMode;
        };
    }
});
