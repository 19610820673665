enum PlanType{
    None = 0,
    TrialWeekly = 1,
    IndividualMonthly_1 = 2,
    IndividualAnnually_1 = 3,

    IndividualMonthly_2_5 = 4,
    IndividualAnnually_2_5 = 5,

    IndividualMonthly_6_10 = 6,
    IndividualAnnually_6_10 = 7,

    IndividualMonthly_11_25 = 8,
    IndividualAnnually_11_25 = 9,

    Monthly = 10,
    Annually = 11
}

export default PlanType;