import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#1a6fe3',
        secondary: '#F4C246',
        success: '#009905',
        error: '#E73055',
        golden: '#924BEA',
        primaryLight: '#f1f7fd',
        bgLightGray: '#f7f8f9'
      },
      dark: {
        primary: '#8bb4f6',
        bgLightGray: "#303135",
        error: '#fd0016',
        primaryLight: '#373F4B'

      }
    },
  },
});

