import { User } from "../models/user.model";
import httpService from "./http.service";
import store from "../store";
import { TeamModel } from "../models/team.model";
import CouponModel from "../models/coupon.model";
import TeamInviteModel from "../models/team.invite.model";
import TeamBatchInviteResult from "../models/team.batchInviteResult.model";
import { TeamInviteResult } from "../models/team.inviteResult.model";
import ReceiptModel from "../models/receipt.model";

class UserService {
    async register(email: string, password: string, confirmPassword: string, recaptchaResponse: string): Promise<boolean> {
        try {
            await httpService.register(email, password, confirmPassword, recaptchaResponse);
            return true;
        } catch {
            return false;
        }
    }

    async getLoginStamp() {
        return await httpService.get("/account/login/stamp");
    }

    async login(email: string, password: string, recaptchaResponse: string, rememberMe: boolean, encodedStamp: string): Promise<boolean> {
        try {
            await httpService.login(email, password, recaptchaResponse, rememberMe, encodedStamp);
            return true;
        } catch {
            return false;
        }
    }

    async sendForgotPasswordEmail(email: string, recaptchaResponse: string): Promise<boolean> {
        try {
            await httpService.sendForgotPasswordEmail(email, recaptchaResponse);
            return true;
        } catch {
            return false;
        }
    }

    async fetchUser(fetchInvoicesAvailable: boolean = false): Promise<boolean> {
        let user: User | null;
        try {
            user = await httpService.getAccount(fetchInvoicesAvailable);
        } finally {
            store.commit("setUserFetchedOnce");
        }
        store.commit("setUser", user);
        return user != null;
    }

    async getInviteUrl(): Promise<string> {
        const res = await httpService.getInviteUrl();
        return res.data.url;
    }

    async changePassword(currentPassword: string, newPassword: string, recaptchaResponse: string) {
        await httpService.changePassword(currentPassword, newPassword, recaptchaResponse);
    }

    async setNewPassword(userId: string, code: string, newPassword: string, recaptchaResponse: string) {
        await httpService.setNewPassword(userId, code, newPassword, recaptchaResponse);
    }

    async resendEmailConfirmation() {
        await httpService.resendEmailConfirmation();
    }

    async deleteAccount(recaptchaResponse: string) {
        await httpService.deleteAccount(recaptchaResponse);
    }

    async logout() {
        await httpService.logout();
    }

    async getScreenshots(search: string, page: number) {
        const res = await httpService.getScreenshots(search, page);
        return res.data;
    }

    async getScreenshot(id: string) {
        const res = await httpService.getScreenshot(id);
        return res.data;
    }

    async getScreenRecords(page: number) {
        const res = await httpService.getScreenRecords(page);
        return res.data;
    }

    async getScreenRecord(id: string) {
        const res = await httpService.getScreenRecord(id);
        return res.data;
    }

    async getStorageInfo() {
        const res = await httpService.getStorageInfo();
        return res.data;
    }

    async sendContact(name: string, email: string, topic: string, comment: string, recaptchaResponse: string) {
        const res = await httpService.sendContactForm(name, email, topic, comment, recaptchaResponse);
        return res.data;
    }

    async getCheckoutLink(usersNumber: number, isYearlyBillingCycle: boolean, couponCode: string) {
        const res = await httpService.getCheckoutLink(usersNumber, isYearlyBillingCycle, couponCode);
        return res.data.url;
    }

    async getTeamInfo(): Promise<TeamModel | null> {
        const res = await httpService.teamInfo();
        return new TeamModel(res.data);
    }

    async inviteToTeam(id: string, email: string) {
        const res = await httpService.inviteToTeam(id, email);
        return new TeamInviteResult(res.data);
    }

    async deleteInvite(id: string) {
        const res = await httpService.deleteInvite(id);
        return res.data;
    }

    async resendInvite(id: string) {
        const res = await httpService.resendInvite(id);
        return res.data;
    }

    async removeFromTeam(teamId: string, userId: string) {
        const res = await httpService.removeFromTeam(teamId, userId);
        return res.data;
    }

    async inviteToTeamFile(id: string, file: File): Promise<TeamBatchInviteResult> {
        const res = await httpService.inviteToTeamFile(id, file);
        return new TeamBatchInviteResult(res.data);
    }

    async acceptInvitation(id: string) {
        const res = await httpService.acceptInvitation(id);
        return res.data;
    }

    async validatePaddleInvoice(invoiceId: string) {
        const res = await httpService.validateInvoice(invoiceId);
        return res.data;
    }

    async getCoupon(code: string): Promise<CouponModel | undefined> {
        const res = await httpService.getCoupon(code);
        if (res.data) {
            return new CouponModel(res.data);
        }
        return undefined;
    }

    async scaleLicense(id: string, quantity: number) {
        await httpService.scaleLicense(id, quantity);
    }

    async cancelRecurring() {
        await httpService.cancelRecurring(store.state.user?.plan?.id as string);
    }

    async cancelLicense() {
        await httpService.cancelLicense(store.state.user?.plan?.id as string);
    }

    async getReceipts() {
        const result = (await httpService.getReceipts()).data;
        return Array.isArray(result) ? result.map((receipt) => new ReceiptModel(receipt)) : [];
    }

    async getUpdatePaymentMethodUrl(): Promise<string> {
        const res = await httpService.getUpdatePaymentMethodUrl();
        return res.data.url;
    }

    async getInvitationInfo(id: string): Promise<TeamInviteModel | undefined> {
        const res = await httpService.getInvitationInfo(id);
        if (res.data) {
            return new TeamInviteModel(res.data);
        }
        return undefined;
    }

    async deleteScreenshot(id: string) {
        await httpService.deleteScreenshot(id);
    }

    async deleteScreenRecord(id: string) {
        await httpService.deleteScreenRecord(id);
    }

    async submitUninstallForm(reasons: Array<string>, otherReason: string, comment: string, email: string, recaptchaResponse: string) {
        await httpService.submitUninstallForm(reasons, otherReason, comment, email, recaptchaResponse);
    }
}

export default new UserService();
