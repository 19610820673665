enum TeamInviteOutcome {
    Invited,
    AddedToTeam
}

class TeamInviteResult {
    result: TeamInviteOutcome;

    constructor(obj: any) {
        this.result = getTeamInviteOutcome(obj.result);
    }
}

function getTeamInviteOutcome(x: number): TeamInviteOutcome {
    if (Object.keys(TeamInviteOutcome).includes(x.toString())) {
        return x;
    }
    throw new Error("unknow team member status");
}

export { TeamInviteOutcome, TeamInviteResult };
