
import Vue from "vue";

export default Vue.extend({
    name: "CustomersView",
    data() {
        return {
            customers: [
                { name: "uefa", height: 32 },
                { name: "deloitte", height: 16 },
                { name: "hp", height: 32 },
                { name: "xerox", height: 32 },
                { name: "nasa", height: 32 },
                { name: "pandora", height: 24 },
                { name: "ul", height: 32 },
                { name: "unicef", height: 16 },
                { name: "vice", height: 16 }
            ],
            industries: [
                "IT",
                "Automotive",
                "Education",
                "Financial Services",
                "Energy",
                "Government",
                "Healthcare",
                "Manufacturing",
                "Travel & Hospitality",
                "Real Estate",
                "Media & Entertainment",
                "Nonprofit",
                "Power & Utilities",
                "Charity",
                "E-commerce & Retail",
                "Telecom",
                "Transportation",
                "Food & Beverage"
            ]
        };
    },
    methods: {},
    computed: {}
});
