import Vue from "vue";
import Vuex from "vuex";
import { User } from "../models/user.model";
import InitialUserFetchResult from "../models/initialUserFetchResult";

Vue.use(Vuex);

interface BliskState {
    initialUserFetchResult: InitialUserFetchResult;
    user: User | null;
    isBliskBrowserNavigator: boolean;
    userFetchedOnce: boolean;
}

const state: BliskState = {
    user: null,
    initialUserFetchResult: InitialUserFetchResult.InProgress,
    isBliskBrowserNavigator: false,
    userFetchedOnce: false
};

export default new Vuex.Store({
    state: state,
    getters: {},
    mutations: {
        setUser(state: BliskState, payload: User) {
            state.user = payload;
        },
        setInitialUserFetchResult(state: BliskState, result: InitialUserFetchResult) {
            state.initialUserFetchResult = result;
        },
        setIsBliskBrowserNavigator(state: BliskState, isBliskBrowserNavigator: boolean) {
            state.isBliskBrowserNavigator = isBliskBrowserNavigator;
        },
        setUserFetchedOnce(state: BliskState) {
            state.userFetchedOnce = true;
        }
    },
    actions: {},
    modules: {}
});

