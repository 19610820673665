
import Vue from "vue";
import { getCookie, setCookie } from "typescript-cookie";
import urlService from "../services/url.service";

export default Vue.extend({
    name: "CookieBannerComponent",
    props: ["isBliskBrowserNavigator"],
    data: () => ({
        showNotificationAgreeToTerms: false,
        privacyPolicyUrl: urlService.PrivacyPolicy.toString(),
        termsUrl: urlService.Terms.toString()
    }),
    beforeMount() {
        if (getCookie("showNotificationAgreeToTerms") === undefined) {
            this.showNotificationAgreeToTerms = true;
        }
    },
    methods: {
        agreeToTerms() {
            setCookie("showNotificationAgreeToTerms", "false", { expires: 1000 });
            this.showNotificationAgreeToTerms = false;
        }
    }
});
