
import Vue from "vue";
import CustomersView from "../components/CustomersView.vue";
import urlSevice from "../services/url.service";

class Tile {
    img: string;
    title: string;
    content: string;
    constructor(i: string, t: string, c: string) {
        this.img = i;
        this.title = t;
        this.content = c;
    }
}

export default Vue.extend({
    name: "HomeComponent",
    props: ["darkMode"],
    metaInfo() {
        return {
            title: this.pageMeta.title,
            meta: [
                { vmid: "description", name: "description", content: this.pageMeta.description },
                { vmid: "keywords", name: "keywords", content: this.pageMeta.keywords },
                { vmid: "og:title", property: "og:title", content: this.pageMeta.title },
                { vmid: "og:description", property: "og:description", content: this.pageMeta.description },
                { vmid: "twitter:title", name: "twitter:title", content: this.pageMeta.title },
                { vmid: "twitter:description", name: "twitter:description", content: this.pageMeta.description }
            ]
        };
    },
    components: { CustomersView },
    data: () => ({
        benefits: [
            new Tile(
                "code-tags",
                "Productive web development",
                "Analyze web page quality, cross-browser compatibility, and performance. Get real-time notifications about page errors and track resources that fail to load."
            ),
            new Tile(
                "account-multiple",
                "Cloud storage, team collaboration",
                "Take and share screenshots, screen records. Personal cloud storage is available from any device. Discuss mockups and design, make notes and code review."
            )
        ],
        features: [
            new Tile(
                "",
                "Devices",
                "Use premium devices - latest popular devices: iPhone 14, iPhone 14 Plus, iPhone 14 Pro, iPhone 14 Pro Max, latest Android mobiles, iPads, and many more"
            ),
            new Tile("", "URL and Scroll sync", "Blisk synchronizes URL and scroll position for both mobile and desktop. Mobile and desktop follow each other by URL and scroll position"),
            new Tile(
                "",
                "Screenshots",
                "Blisk takes a screenshot of devices or desktop in one click and saves the screenshot in the cloud. You can share screenshots with teammates, attach them to bug reports"
            ),
            new Tile("", "Screen recorder", "Blisk records your screen and saves recording in the cloud. You can share screen recordings with teammates or attach them to bug reports"),
            new Tile("", "Cloud storage", "Blisk creates secure cloud storage for screenshots, screen records, reports, etc. Cloud storage powers teamwork and team collaboration"),
            new Tile(
                "",
                "Page Auto-refresh",
                "Blisk refreshes web page automatically every time you save code changes. Try to open IDE in one screen, Blisk - in another and focus on web development"
            ),
            new Tile(
                "",
                "Error Notifications",
                "Blisk shows real-time notifications about resources that failed to load and JS errors. Blisk shows stack trace of errors and searches for the solution on StackOverflow"
            ),
            new Tile(
                "",
                "Command Line",
                "You can share custom Device Set and Url with your team, launch Blisk from Command Prompt and Terminal, or launch Blisk from other browsers by using Blisk link"
            )
        ],
        pageMeta: {
            title: "Blisk Premium",
            description: "Enable Blisk Premium and create Blisk account.",
            keywords: "Premium, account, Blisk, browser, blisk.io"
        }
    }),
    computed: {
        downloadUrl(): string {
            return urlSevice.DownloadUrl.toString();
        }
    }
});
