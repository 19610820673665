enum TeamMemberStatus {
    Member,
    Invited
}

class TeamMemberModel {
    id: string;
    email: string;
    status: TeamMemberStatus;

    constructor(data: any) {
        this.id = data.id;
        this.email = data.email;
        this.status = getTeamMemberStatus(data.status);
    }
}

class TeamModel {
    id: string;
    quota: number;
    members: Array<TeamMemberModel>;

    constructor(data: any) {
        this.id = data.id;
        this.quota = Number(data.quota);
        this.members = data.members.map((x: any) => new TeamMemberModel(x));
    }
}

function getTeamMemberStatus(x: number): TeamMemberStatus {
    if (Object.keys(TeamMemberStatus).includes(x.toString())) {
        return x;
    }
    throw new Error("unknow team member status");
}

export { TeamMemberStatus, TeamMemberModel, TeamModel };
