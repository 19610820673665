export default class CouponModel {
    code: string;
    monthlyDiscount: number;
    annualDiscount: number;

    constructor(obj: any) {
        this.code = obj.code;
        this.monthlyDiscount = obj.monthlyDiscount && obj.monthlyDiscount > 0 ? Number(obj.monthlyDiscount) : 0;
        this.annualDiscount = obj.annualDiscount && obj.annualDiscount > 0 ? Number(obj.annualDiscount) : 0;
    }
}
