export default class TeamBatchInviteResult {
    succeeded: number;
    failed: number;
    quotaExceeded: boolean;

    constructor(obj: any) {
        this.succeeded = Number(obj.succeeded) || 0;
        this.failed = Number(obj.failed) || 0;
        this.quotaExceeded = Boolean(obj.quotaExceeded);
    }
}
