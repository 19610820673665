import UrlService from "./url.service";
import { User } from "../models/user.model";
import axios from "axios";
import { router, anonymousPaths } from "../router";
import store from "../store";
import InitialUserFetchResult from "../models/initialUserFetchResult";
import { EventBus } from "../eventBus";
import PlanOrigin from "../models/planOrigin";
import { getCookie, setCookie } from "typescript-cookie";

enum Headers {
    BliskBrowserIdHeader = "Blisk-Browser-Id",
    BliskBrowserMachineIdHeader = "Blisk-Browser-Machine-Id",
    BliskBrowserVersionHeader = "Blisk-Browser-Version"
}

enum Cookies {
    MachineId = "MachineId",
    BliskBrowserId = "BliskBrowserId",
    BliskBrowserVersion = "BliskBrowserVersion"
}

type HeaderType = {
    [key in Headers]?: string;
};

class HttpService {
    instance;
    constructor() {
        this.instance = axios.create({
            withCredentials: true,
            baseURL: UrlService.ApiBaseUrl.toString(),
            headers: {
                "bl-client-id": "2ab8e848-dace-454b-897b-a80654261b37"
            }
        });
        
        this.instance.interceptors.request.use(
            function (config) {
                const extend: HeaderType = {
                    "Blisk-Browser-Id": getCookie(Cookies.BliskBrowserId),
                    "Blisk-Browser-Machine-Id": getCookie(Cookies.MachineId),
                    "Blisk-Browser-Version": getCookie(Cookies.BliskBrowserVersion)
                };
                config.headers = config.headers || {};
                let key: keyof HeaderType;
                for (key in extend) {
                    const val = extend[key];
                    if (val) {
                        config.headers[key] = val;
                    }
                }
                return config;
            },
            function (error) {
                // Do something with request error
                return Promise.reject(error);
            }
        );

        this.instance.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.response.status === 401) {
                    store.commit("setUser", null);

                    let redirectToLogin: boolean = true;
                    const resolve = router.resolve(window.location.pathname);
                    if (resolve.resolved.name) {
                        redirectToLogin = !anonymousPaths.includes(resolve.resolved.name);
                    }
                    redirectToLogin && router.push({ name: "login" });
                } else {
                    if (error?.response?.data?.errorMessage) {
                        EventBus.$emit("notify-error", error.response.data.errorMessage);
                    } else {
                        EventBus.$emit("notify-error", "Something went wrong, please try again.");
                    }
                }
                throw error;
            }
        );
    }

    async get(url: string, queryParams: object = {}): Promise<any> {
        queryParams = queryParams != null ? queryParams : {};
        const config = { params: queryParams };

        const result = await this.instance.get(url, config);
        return result.data;
    }

    async post(url: string, params: object = {}) {
        const result = await this.instance.post(url, params);
        return result.data;
    }

    async uploadFileInChunks(fileParamName: string, chunkUrl: string, completeUrl: string, file: File, params: object = {}) {
        const CHUNK_SIZE = 1024 * 1024 * 10;
        const fileSize = file.size;
        const totalChunks = Math.ceil(fileSize / CHUNK_SIZE);
        let uploadedChunks = 0;

        const chunkBlocks = [];

        for (let i = 0; i < totalChunks; i++) {
            const start = i * CHUNK_SIZE;
            const end = Math.min(start + CHUNK_SIZE, fileSize);

            const chunkBlob: Blob = file.slice(start, end);
            const chunkBlock = await this.uploadChunk(chunkUrl, i, fileParamName, file.name, chunkBlob, params);
            chunkBlocks.push(chunkBlock);

            uploadedChunks++;
            if (uploadedChunks === totalChunks) {
                (params as any)["blocks"] = chunkBlocks;
                (params as any)["fileName"] = file.name;

                await this.post(completeUrl, params);
            }
        }
    }

    private async uploadChunk(chunkUrl: string, chunkIndex: number, fileParamName: string, fileName: string, chunkBlob: Blob, params: object = {}) {
        const formData = new FormData();
        formData.append(fileParamName, chunkBlob, fileName);
        formData.append("chunkIndex", chunkIndex.toString());
        for (const [key, value] of Object.entries(params)) {
            formData.append(key, value);
        }

        console.log(`uploading chunk ${chunkIndex}`);
        const res = await this.instance.post(chunkUrl, formData, { headers: { "Content-Type": "multipart/form-data" } });
        return res.data.blockName;
    }

    async postFileMultipartFormData(fileParamName: string, url: string, file: File, params: object = {}) {
        const formData = new FormData();
        formData.append(fileParamName, file);

        for (const [key, value] of Object.entries(params)) {
            formData.append(key, value);
        }

        await this.instance.post(url, formData, { headers: { "Content-Type": "multipart/form-data" } });
    }

    async postDownload(url: string, params: object = {}) {
        const response = await this.instance.post(url, params, { responseType: "blob" });

        let fileName: string = new Date().getTime().toString();
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(response.headers["content-disposition"]);
        if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "");
        }

        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    register(email: string, password: string, confirmPassword: string, recaptchaResponse: string) {
        return this.instance.post("/account/register", {
            email: email,
            password: password,
            confirmPassword: confirmPassword,
            recaptchaResponse: recaptchaResponse
        });
    }

    login(email: string, password: string, recaptchaResponse: string, rememberMe: boolean, encodedStamp: string) {
        return this.instance.post(
            "/account/login",
            {
                email: email,
                password: password,
                recaptchaResponse: recaptchaResponse,
                rememberMe: rememberMe,
                stamp: encodedStamp
            },
            {
                headers: {
                    "Xsrf-Token": encodedStamp
                }
            }
        );
    }

    sendForgotPasswordEmail(email: string, recaptchaResponse: string) {
        return this.instance.post("/account/forgot-password", {
            email: email,
            recaptchaResponse: recaptchaResponse
        });
    }

    async logout() {
        return await this.instance.get("/account/logout");
    }

    async resendEmailConfirmation() {
        return this.instance.post("/account/resend-email-confirmation", {});
    }

    async changePassword(currentPassword: string, newPassword: string, recaptchaResponse: string) {
        await this.instance.post("/account/change-password", {
            currentPassword: currentPassword,
            newPassword: newPassword,
            recaptchaResponse: recaptchaResponse
        });
    }

    async setNewPassword(userId: string, code: string, newPassword: string, recaptchaResponse: string) {
        await this.instance.post("/account/new-password", {
            userId: userId,
            code: code,
            newPassword: newPassword,
            recaptchaResponse: recaptchaResponse
        });
    }

    async deleteAccount(recaptchaResponse: string) {
        await this.instance.post("/account/delete", {
            recaptchaResponse: recaptchaResponse
        });
    }

    async getAccount(fetchInvoicesAvailable: boolean): Promise<User | null> {
        let initialUserFetchResult: InitialUserFetchResult = InitialUserFetchResult.Success;
        try {
            const config = { params: fetchInvoicesAvailable ? { fetchInvoicesAvailable: fetchInvoicesAvailable } : {} };

            const res = await this.instance.get("/account", config);
            return this.getUser(res.data);
        } catch (err: any) {
            if (err?.response?.status === 401) {
                return null;
            }
            initialUserFetchResult = InitialUserFetchResult.Failed;

            throw err;
        } finally {
            store.commit("setInitialUserFetchResult", initialUserFetchResult);
        }
    }

    async getInviteUrl() {
        return this.instance.get("/invite/url");
    }

    getScreenshots(search: string, page: number) {
        const config = { params: { page: page, search: search } };

        return this.instance.get("/screenshots/list", config);
    }

    getScreenshot(id: string) {
        return this.instance.get(`/screenshots/${id}`);
    }

    getScreenRecords(page: number) {
        const config = { params: { page: page } };
        return this.instance.get("/screen-records/list", config);
    }

    getScreenRecord(id: string) {
        return this.instance.get(`/screen-records/${id}`);
    }

    getStorageInfo() {
        return this.instance.get("/storage");
    }

    sendContactForm(name: string, email: string, topic: string, comment: string, recaptchaResponse: string) {
        return this.instance.post("/contact", {
            name: name,
            email: email,
            topic: topic,
            comment: comment,
            recaptchaResponse: recaptchaResponse
        });
    }

    async getCheckoutLink(usersNumber: number, isYearlyBillingCycle: boolean, couponCode: string) {
        const config = {
            params: {
                usersNumber: usersNumber,
                annualBilling: isYearlyBillingCycle,
                couponCode: couponCode
            }
        };
        return this.instance.get("/order/checkouturl", config);
    }

    async teamInfo() {
        return this.instance.get("/team");
    }

    async inviteToTeam(id: string, email: string) {
        return this.instance.post("/team/invite", {
            id: id,
            email: email
        });
    }

    async deleteInvite(id: string) {
        return this.instance.post("/team/invite/delete", {
            id: id
        });
    }

    async resendInvite(id: string) {
        return this.instance.post("/team/invite/resend", {
            id: id
        });
    }

    async removeFromTeam(teamId: string, userId: string) {
        return this.instance.post("/team/remove-member", {
            id: teamId,
            userId: userId
        });
    }

    async inviteToTeamFile(id: string, file: File) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("id", id);

        return this.instance.post("/team/invite/csv", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    }

    async acceptInvitation(id: string) {
        return this.instance.post("/team/accept-invitation", {
            id: id
        });
    }

    async validateInvoice(invoiceId: string) {
        return this.instance.get("/order/validate", { params: { invoice: invoiceId, origin: PlanOrigin.Paddle } });
    }

    async getCoupon(code: string) {
        return this.instance.get("/coupon", { params: { code: code } });
    }

    async scaleLicense(id: string, quantity: number) {
        return this.instance.post("/license/scale", {
            id: id,
            quantity: quantity
        });
    }

    async cancelRecurring(id: string) {
        return this.instance.post("/license/stop-renewal", {
            id: id
        });
    }

    async cancelLicense(id: string) {
        return this.instance.post("/license/cancel", {
            id: id
        });
    }

    async getReceipts() {
        return this.instance.get("/license/receipts");
    }

    async getUpdatePaymentMethodUrl() {
        return this.instance.get(`/license/update-url`);
    }

    async getInvitationInfo(id: string) {
        return this.instance.get(`/team/get-invite/${id}`);
    }

    async deleteScreenshot(id: string) {
        return this.instance.post("/screenshots/delete", {
            id: id
        });
    }

    async deleteScreenRecord(id: string) {
        return this.instance.post("/screen-records/delete", {
            id: id
        });
    }

    async submitUninstallForm(reason: Array<string>, reasonOther: string, improve: string, email: string, recaptchaResponse: string) {
        return this.instance.post("/uninstall", {
            reason,
            reasonOther,
            improve,
            email,
            recaptchaResponse
        });
    }

    private getUser(data: any): User | null {
        if (!data || !data.email) {
            return null;
        }
        return new User(data);
    }
}

export default new HttpService();
