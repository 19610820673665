class UrlService {
    get LandingBaseUrl(): URL {
        return new URL(process.env.VUE_APP_LANDING_BASE_URL);
    }

    get ApiBaseUrl(): URL {
        return new URL(`${process.env.VUE_APP_API_BASE_URL}api`);
    }

    get DownloadUrl(): URL {
        const result = new URL(this.LandingBaseUrl);
        result.searchParams.set("download", "browser");
        return result;
    }

    get TutorialUrl(): URL {
        return new URL("/tutorial", this.LandingBaseUrl);
    }

    get PrivacyPolicy(): URL {
        return new URL("/privacy", this.LandingBaseUrl);
    }

    get Terms(): URL {
        return new URL("/terms", this.LandingBaseUrl);
    }

    get GettingStartedUrl(): URL {
        return new URL("/questions/getting-started-with-blisk-browser", this.LandingBaseUrl);
    }

    get GettingStartedUrl_TroubleShootingSection(): URL {
        return this.GetUrlWithHash(this.GettingStartedUrl, "troubleshooting");
    }

    get latestRelease(): URL {
        return new URL("/blog/release", this.LandingBaseUrl);
    }

    private GetUrlWithHash(url: URL, hash: string): URL {
        const result = new URL(url);
        result.hash = hash;
        return result;
    }
}

export default new UrlService();
