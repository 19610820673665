require("../assets/css/styles.scss");

import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "../store";
import UserService from "../services/user.service";
import VueMeta from "vue-meta";
import AdminRoutes from "./admin.routes";
import { UserRoleType } from "../models/user.role.type.model";

Vue.use(VueRouter);
Vue.use(VueMeta, {
    keyName: "metaInfo",
    attribute: "data-vue-meta",
    ssrAttribute: "data-vue-meta-server-rendered",
    tagIDKeyName: "vmid",
    refreshOnceOnNavigation: true
});

const routes: Array<RouteConfig> = [
    ...AdminRoutes,
    {
        path: "*",
        name: "page-not-found",
        meta: { pageBgColor: "light-gray", fillHeight: true },
        component: () => import(/* webpackChunkName: "account" */ "../views/PageNotFound.vue")
    },
    {
        path: "/",
        name: "home",
        component: HomeView
    },
    {
        path: "/account/welcome",
        name: "auth-welcome",
        meta: { pageBgColor: "light-gray", fillHeight: true },
        component: () => import(/* webpackChunkName: "account" */ "../views/auth/AuthWelcomeScreen.vue")
    },
    {
        path: "/uninstall",
        name: "uninstall",
        meta: { pageBgColor: "light-gray", fillHeight: true },
        component: () => import(/* webpackChunkName: "account" */ "../views/UninstallView.vue")
    },
    {
        path: "/account/login",
        name: "login",
        meta: { pageBgColor: "light-gray", fillHeight: true },
        component: () => import(/* webpackChunkName: "account" */ "../views/auth/LoginView.vue")
    },
    {
        path: "/account/register",
        name: "register",
        meta: { pageBgColor: "light-gray", fillHeight: true },
        component: () => import(/* webpackChunkName: "account" */ "../views/auth/RegisterView.vue")
    },
    {
        path: "/account/forgotpassword",
        name: "forgotpassword",
        meta: { pageBgColor: "light-gray", fillHeight: true },
        component: () => import(/* webpackChunkName: "account" */ "../views/auth/ForgotPassword.vue")
    },
    {
        path: "/change-password",
        name: "changepasswordObsolete",
        meta: { pageBgColor: "light-gray", fillHeight: true },
        component: () => import(/* webpackChunkName: "account" */ "../views/auth/ChangePassword.vue")
    },
    {
        path: "/account/changepassword",
        name: "changepassword",
        meta: { pageBgColor: "light-gray", fillHeight: true },
        component: () => import(/* webpackChunkName: "account" */ "../views/auth/ChangePassword.vue")
    },
    {
        path: "/account/new-password",
        name: "newpassword",
        meta: { pageBgColor: "light-gray", fillHeight: true },
        component: () => import(/* webpackChunkName: "account" */ "../views/auth/NewPassword.vue")
    },
    {
        path: "/account",
        name: "account",
        component: () => import(/* webpackChunkName: "account" */ "../views/AccountView.vue")
    },
    {
        path: "/account/logoff",
        name: "logoff",
        component: () => import(/* webpackChunkName: "account" */ "../views/AccountLogoff.vue")
    },
    {
        path: "/account/license-activated",
        name: "license-activated",
        component: () => import(/* webpackChunkName: "account" */ "../views/AccountView.vue")
    },
    {
        path: "/account/billing",
        name: "billing",
        component: () => import(/* webpackChunkName: "account" */ "../views/billing/BillingView.vue")
    },
    {
        path: "/invite",
        name: "invite",
        component: () => import(/* webpackChunkName: "account" */ "../views/invite/InviteView.vue")
    },
    {
        path: "/account/delete",
        name: "deleteaccount",
        meta: { pageBgColor: "light-gray" },
        component: () => import(/* webpackChunkName: "account" */ "../views/DeleteAccountView.vue")
    },
    {
        path: "/team/manage",
        name: "teammanagement",
        // meta: {layout: "full-width"},
        meta: { pageBgColor: "light-gray" },
        component: () => import(/* webpackChunkName: "account" */ "../views/TeamManagement.vue")
    },
    {
        path: "/team/accept-invitation/:id",
        name: "teamacceptinvitation",
        // meta: {layout: "full-width"},
        meta: { pageBgColor: "light-gray" },
        component: () => import(/* webpackChunkName: "account" */ "../views/AcceptInviteView.vue")
    },
    {
        path: "/contact",
        name: "contact",
        meta: { pageBgColor: "light-gray", fillHeight: true },
        component: () => import(/* webpackChunkName: "account" */ "../views/ContactView.vue")
    },
    {
        path: "/contact/crash",
        name: "contact-crash",
        meta: { pageBgColor: "light-gray" },
        component: () => import(/* webpackChunkName: "account" */ "../views/ContactView.vue")
    },
    {
        path: "/pricing",
        name: "pricing",
        component: () => import(/* webpackChunkName: "account" */ "../views/pricing/PricingView.vue")
    },
    {
        path: "/storage",
        name: "storage",
        component: () => import(/* webpackChunkName: "storage" */ "../views/assets/StorageView.vue")
    },
    {
        path: "/screenshots",
        name: "screenshot-list",
        meta: { pageBgColor: "light-gray" },
        component: () => import(/* webpackChunkName: "storage" */ "../views/assets/ScreenshotsVue.vue")
    },
    {
        path: "/screenshots/shared/:id",
        name: "screenshot",
        meta: { pageBgColor: "light-gray" },
        component: () => import(/* webpackChunkName: "storage" */ "../views/assets/ScreenshotView.vue")
    },
    {
        path: "/screenrecords",
        name: "screenrecord-list",
        meta: { pageBgColor: "light-gray" },
        component: () => import(/* webpackChunkName: "storage" */ "../views/assets/ScreenRecordsView.vue")
    },
    {
        path: "/screenrecords/shared/:id",
        name: "screenrecord",
        component: () => import(/* webpackChunkName: "storage" */ "../views/assets/ScreenRecordView.vue")
    },
    {
        path: "/browser/postlogin",
        name: "blisk-browser-post-login",
        component: () => import(/* webpackChunkName: "browser" */ "../views/auth/BliskBrowserPostLoginView.vue")
    },
    {
        path: "/about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue")
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: "smooth",
                offset: {
                    x: 0,
                    y: 88
                }
            };
        } else if (savedPosition) {
            return savedPosition;
        } else {
            // always scroll to top on new URL
            return { x: 0, y: 0 };
        }
    }
});

let fetchedUserOnce: boolean = false;
const anonymousPaths = [
    "page-not-found",
    "uninstall",
    "home",
    "login",
    "register",
    "forgotpassword",
    "contact",
    "contact-crash",
    "pricing",
    "teamacceptinvitation",
    "screenrecord",
    "screenshot",
    "newpassword",
    "auth-welcome"
];

router.beforeEach(async (to: Route, from, next) => {
    if (!fetchedUserOnce || to.name === "account") {
        fetchedUserOnce = true;
        await UserService.fetchUser();
    }

    const isAuthenticated = store.state.user != null;
    const isChildOrder = store.state.user && store.state.user?.plan?.isChildOrder && !store.state.user.plan.isTeamAdmin;

    const userRoleType: UserRoleType | undefined = store?.state?.user?.roleType;
    const isAdmin = userRoleType === UserRoleType.SuperAdmin;
    if (/admin/.test(to.path) && !isAdmin) {
        console.error("Not enough permissions to accessa admin area");
        next({ name: "account" });
    }

    if (!isAuthenticated && !anonymousPaths.includes(to.name ?? "")) {
        next({ name: "login", query: { returnUrl: window.location.pathname } });
        return;
    }

    if (isAuthenticated && ["login", "register", "forgotpassword"].includes(to.name ?? "")) {
        next({ name: "account" });
        return;
    }

    if (to.name == "teammanagement" && !store.state.user?.plan?.isTeamAdmin) {
        next({ name: "account" });
    }

    if (isAuthenticated && isChildOrder && ["teammanagement", "pricing"].includes(to.name ?? "")) {
        // REDIRECT CHILD ORDERS FROM PRICING AND TEAM MANAGEMENT TO ACCOUNT
        next({ name: "account" });
        return;
    }

    next();
});

export { router, anonymousPaths };
