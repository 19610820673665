import { RouteConfig } from "vue-router";
import VueMeta from "vue-meta";

const routes: Array<RouteConfig> = [
    {
        path: "/admin",
        name: "admin-home-page",
        meta: { fillHeight: true },
        component: () => import(/* webpackChunkName: "admin" */ "../views/admin/AdminView.vue")
    },
    {
        path: "/admin/users",
        name: "admin-users-list",
        component: () => import(/* webpackChunkName: "admin" */ "../views/admin/users/listView.vue")
    },
    {
        path: "/admin/users/:id",
        name: "admin-user-details",
        meta: {layout: "full-width"},
        component: () => import(/* webpackChunkName: "admin" */ "../views/admin/users/detailsView.vue")
    },
    {
        path: "/admin/coupons",
        name: "admin-coupons-list",
        meta: {layout: "full-width"},
        component: () => import(/* webpackChunkName: "admin" */ "../views/admin/coupons/listView.vue")
    },
    {
        path: "/admin/coupons/new",
        name: "admin-coupon-new",
        component: () => import(/* webpackChunkName: "admin" */ "../views/admin/coupons/newOrEditCouponView.vue")
    },
    {
        path: "/admin/coupons/:id",
        name: "admin-coupon-details",
        component: () => import(/* webpackChunkName: "admin" */ "../views/admin/coupons/newOrEditCouponView.vue")
    },
    {
        path: "/admin/installers",
        name: "admin-installers-stable",
        component: () => import(/* webpackChunkName: "admin" */ "../views/admin/installers/listView.vue")
    },
    {
        path: "/admin/installers/beta",
        name: "admin-installers-beta",
        component: () => import(/* webpackChunkName: "admin" */ "../views/admin/installers/listView.vue")
    },
    {
        path: "/admin/browser-notifications/list",
        name: "admin-browser-notifications-list",
        component: () => import(/* webpackChunkName: "admin" */ "../views/admin/browserNotifications/listView.vue")
    },
    {
        path: "/admin/browser-notifications/:id",
        name: "admin-browser-notification-details",
        component: () => import(/* webpackChunkName: "admin" */ "../views/admin/browserNotifications/newOrEditBrowserNotificationView.vue")
    },
    {
        path: "/admin/browser-notifications/new",
        name: "admin-browser-notification-new",
        component: () => import(/* webpackChunkName: "admin" */ "../views/admin/browserNotifications/newOrEditBrowserNotificationView.vue")
    }
];

export default routes;
